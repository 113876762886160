/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://434g4di4dzbcrpb335g72zjsra.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "pcoverifypbcjwtapi",
            "endpoint": "https://lvftuor788.execute-api.us-east-1.amazonaws.com/sandbox",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:9d08cead-4cee-4632-9343-9b0bb446b7af",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_odD15FiC2",
    "aws_user_pools_web_client_id": "3k43vjlmsag3j2f60h26q2kd3n",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Configuration-sandbox",
            "region": "us-east-1"
        },
        {
            "tableName": "Reporting-sandbox",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "pco-card-art120041-sandbox",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
