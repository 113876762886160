/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/styles/global.scss"
import React from "react"
import AppContextProvider from "./src/context/appContext"
import { CacheProvider } from "@emotion/react"
import { myCache } from "./create-emotion-cache"

export const onClientEntry = () => {
  if (window.location.pathname.indexOf("unsupported-browser") !== -1) {
    // Already at the unsupported-browser page so don't double redirect
    return
  }
  // Quick and dirty check for IE browsers
  if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > -1) {
    window.location.href = "/unsupported-browser/"
  }
}

export const wrapRootElement = ({ element }) => (
  <AppContextProvider>
    <CacheProvider value={myCache}>{element}</CacheProvider>
  </AppContextProvider>
)

export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}
