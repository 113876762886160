export const UPDATE_CARD_FRONT = "'UPDATE_CARD_FRONT'"
export const UPDATE_CARD_BACK = "'UPDATE_CARD_BACK'"
export const UPDATE_ACCESSORIES = "UPDATE_ACCESSORIES"
export const UPDATE_SHIPPING = "UPDATE_SHIPPING"
export const RESET_ORDER = "RESET_ORDER"
export const SET_ORDER_HISTORY = "SET_ORDER_HISTORY"
export const SET_LOGGED_IN = "SET_LOGGED_IN"
export const SET_IS_LOADING = "SET_IS_LOADING"
export const SET_RECENT_ORDER = "SET_RECENT_ORDER"
export const SET_ORDER = "SET_ORDER"
export const SET_ORDER_TYPE = "SET_ORDER_TYPE"
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION"
export const SET_LOCATION_MANAGEMENT = "SET_LOCATION_MANAGEMENT"
export const UPDATE_PAYMENT_DETAILS = "UPDATE_PAYMENT_DETAILS"
export const UPDATE_PROMO_DETAILS = "UPDATE_PROMO_DETAILS"
export const SET_PROFILE_ADDRESS = "SET_PROFILE_ADDRESS"
export const UPDATE_DESIGNER_DETAILS = "UPDATE_DESIGNER_DETAILS"
