import React, { createContext, useReducer, useEffect } from "react"
import { isBrowser } from "../services/auth"
import {
  UPDATE_CARD_FRONT,
  UPDATE_CARD_BACK,
  UPDATE_SHIPPING,
  RESET_ORDER,
  UPDATE_ACCESSORIES,
  SET_ORDER_HISTORY,
  SET_LOGGED_IN,
  SET_IS_LOADING,
  SET_RECENT_ORDER,
  SET_ORDER,
  SET_ORDER_TYPE,
  UPDATE_NOTIFICATION,
  SET_LOCATION_MANAGEMENT,
  UPDATE_PAYMENT_DETAILS,
  UPDATE_PROMO_DETAILS,
  SET_PROFILE_ADDRESS,
  UPDATE_DESIGNER_DETAILS,
} from "../constants/actionTypes"
import { AUTH_PROVIDER_PBC, AUTH_PROVIDER_CLOVER } from "../constants/authProvider"
import { Auth, Hub } from "aws-amplify"
import { initConfig } from "../services/amplify"
import { cardCardCoverDefaults, getGoogleFontUrl } from "../services/card"
import { fetchProfileAddress } from "../services/profile"
import { PHYSICAL_CARD_TERMS } from "../constants/terms"

Auth.configure(initConfig())

export const AppStateContext = createContext()
export const AppDispatchContext = createContext()

const sourceList = [AUTH_PROVIDER_PBC, AUTH_PROVIDER_CLOVER]

const initialState = {
  loggedIn: false,
  isLoading: false,
  authProvider: AUTH_PROVIDER_PBC,
  user: {},
  order: {
    cards: {
      cardType: "undefined",
      subCardType: undefined,
      amountOfCards: undefined,
      cardFront: {
        fontFamily: {
          family: "Montserrat",
          url: getGoogleFontUrl("Montserrat"),
        },
        fontColor: {
          hex: "#000000",
        },
        cover: "Solid Light Pink",
        coverTab: 0,
        imgData: "",
        formVisible: true,
        line1: {
          text: "Business Name",
          size: 26,
          sizePrint: 69,
          x: cardCardCoverDefaults.line1_x,
          y: cardCardCoverDefaults.line1_y,
        },
        line2: {
          text: "Business Address",
          size: 20,
          sizePrint: 52,
          x: cardCardCoverDefaults.line2_x,
          y: cardCardCoverDefaults.line2_y,
        },
        line3: {
          text: "GIFT CARD",
          size: 14,
          sizePrint: 36,
          x: cardCardCoverDefaults.line3_x,
          y: cardCardCoverDefaults.line3_y,
        },
      },
      cardBack: {
        line1: {
          text: "Line 1",
          fontSize: 22,
          sizePrint: 69,
        },
        line2: {
          text: "Second line goes here!",
          fontSize: 16,
          sizePrint: 52,
        },
        line3: {
          text: "GIFT CARD",
          fontSize: 14,
          sizePrint: 36,
        },
        terms: {
          text: PHYSICAL_CARD_TERMS,
          fontSize: 10,
          sizePrint: 29,
        },
        imgData: "",
      },
      contactDetails: {
        businessName: "",
        email: "",
        phoneNumber: "",
      },
      designerAssets: {
        name: "",
        url: "",
      },
      designerAssetsUrl: "",
      notes: "",
    },
    accessories: {
      selectedCarrierType: "JC-SIL",
      selectedCarrierAmount: 0,
      countertopDisplays: 0,
      doorDecals: 0,
      summary: [],
    },
    shipping: {},
    payment: {
      paymentMethod: "AFTER_MERCHANT_ACCOUNT",
      paymentStatus: "START",
      paymentDetails: {
        cardholder: "",
        cardType: "",
        lastFour: "",
        billingAddress1: "",
        billingAddress2: "",
        billingCity: "",
        billingState: "",
        billingZipCode: "",
      },
      promoDetails: {
        promoCode: ``,
        value: 0,
        type: ``,
        text: ``,
        discount: 0,
        params: ``,
      },
    },
  },
  orderHistory: null,
  recentOrder: {
    cards: {
      cardType: "standard",
      subCardType: undefined,
      cardFront: {
        amountOfCards: 0,
        imgData: "",
      },
      cardBack: {
        imgData: "",
      },
    },
    accessories: {
      summary: [],
    },
    shipping: {
      shipToAddress: {
        label: "",
        shipAddress1: "",
        shipCity: "",
        shipState: "",
        shipZipCode: "",
      },
    },
    vlOrderId: "",
    createdAt: "",
    payment: {
      paymentMethod: "AFTER_MERCHANT_ACCOUNT",
      paymentStatus: "START",
    },
  },
  notification: {
    show: false,
    type: ``,
    text: ``,
  },
  locationManagement: null,
  profileAddress: {
    label: "",
    shipAddress1: "",
    shipAddress2: "",
    shipCity: "",
    shipState: "",
    shipZipCode: "",
  },
}

const cloneInitialState = () => {
  return JSON.parse(JSON.stringify(initialState))
}

const getPcoState = () => {
  if (!isBrowser()) return null
  return window.localStorage.getItem("pcoState")
}

const getSessionState = () => {
  const pcoState = getPcoState()
  if (pcoState === null) {
    console.info("Starting a new session")
    return cloneInitialState()
  }

  try {
    const sessionState = JSON.parse(pcoState)

    // NOTE: Validate that our current sessionState is compatible with current build
    if (
      !sessionState.hasOwnProperty("loggedIn") ||
      !sessionState.hasOwnProperty("isLoading") ||
      !sessionState.hasOwnProperty("order") ||
      !sessionState.order.hasOwnProperty("cards") ||
      !sessionState.order.hasOwnProperty("shipping") ||
      !sessionState.order.hasOwnProperty("accessories") ||
      !sessionState.order.cards.hasOwnProperty("cardFront") ||
      !sessionState.order.cards.hasOwnProperty("cardBack")
    ) {
      console.warn("Resetting invalid sessionState in window.sessionStorage", sessionState)
      return cloneInitialState()
    }

    return sessionState
  } catch (e) {
    console.error("Resetting invalid sessionState", e)
    return cloneInitialState()
  }
}

export const isSessionActive = () => {
  const sessionState = JSON.parse(getPcoState())
  if (!!sessionState && sessionState.hasOwnProperty("loggedIn")) {
    return sessionState.loggedIn
  }
  return false
}

export const isSessionLoading = () => {
  const sessionState = JSON.parse(getPcoState())
  if (!!sessionState && sessionState.hasOwnProperty("isLoading")) {
    if (sessionState.hasOwnProperty("loggedIn") && sessionState.loggedIn) {
      return false
    } else {
      return sessionState.isLoading
    }
  }
  return false
}

export const getSessionStateForReOrder = () => {
  const sessionState = JSON.parse(getPcoState())

  // Removed unneeded data from the sessionState
  if (sessionState.hasOwnProperty("order") && sessionState.order.hasOwnProperty("cards")) {
    if (sessionState.order.cards.hasOwnProperty("cardFront"))
      delete sessionState.order.cards.cardFront.imgData
    if (sessionState.order.cards.hasOwnProperty("cardBack"))
      delete sessionState.order.cards.cardBack.imgData
  }
  // if (sessionState.hasOwnProperty("order") && sessionState.order.hasOwnProperty("payment") && sessionState.order.payment.hasOwnProperty("paymentDetails")) {
  //   delete sessionState.order.payment.paymentDetails
  // }
  delete sessionState.orderHistory
  delete sessionState.recentOrder

  return JSON.stringify(sessionState)
}

function reducer(state, action) {
  switch (action.type) {
    case UPDATE_CARD_FRONT:
      return {
        ...state,
        order: {
          ...state.order,
          cards: {
            ...state.order.cards,
            cardType: action.payload.cardType,
            amountOfCards: action.payload.amountOfCards,
            cost: action.payload.cost,
            cardFront: {
              // NOTE: We have to do this to merge the state between deluxe and standard
              ...state.order.cards.cardFront,
              ...action.payload.cardFront,
            },
          },
        },
      }

    case UPDATE_DESIGNER_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          cards: {
            ...state.order.cards,
            cardType: action.payload.cardType,
            amountOfCards: action.payload.amountOfCards,
            cost: action.payload.cost,
            contactDetails: action.payload.contactDetails,
            designerAssets: action.payload.designerAssets,
            designerAssetsUrl: action.payload.designerAssetsUrl,
            notes: action.payload.notes,
          },
        },
      }

    case UPDATE_CARD_BACK:
      return {
        ...state,
        order: {
          ...state.order,
          cards: {
            ...state.order.cards,
            cardBack: {
              ...action.payload,
            },
          },
        },
      }

    case UPDATE_ACCESSORIES:
      return {
        ...state,
        order: {
          ...state.order,
          accessories: {
            ...action.payload,
          },
        },
      }

    case UPDATE_SHIPPING:
      return {
        ...state,
        order: {
          ...state.order,
          shipping: {
            ...action.payload,
          },
        },
      }

    case UPDATE_PAYMENT_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          payment: {
            // NOTE: We need to merge in the payment method
            ...state.order.payment,
            paymentDetails: {
              ...state.order.payment.paymentDetails,
              ...action.payload,
            },
          },
        },
      }

    case RESET_ORDER:
      return {
        ...state,
        order: initialState.order,
      }

    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      }

    case SET_ORDER_TYPE:
      // NOTE: When changing order type we want to be sure to reset
      // amountOfCards as not all types support the same amounts
      // imgData should be reset to prevent mixing deluxe and standard images
      return {
        ...state,
        order: {
          ...state.order,
          cards: {
            ...state.order.cards,
            cardType: action.payload,
            amountOfCards: undefined,
            cardFront: {
              ...state.order.cards.cardFront,
              imgData: "",
            },
          },
          payment: {
            ...state.order.payment,
            paymentMethod:
              (state.user.paymentMethod &&
                state.user.paymentMethod === "BEFORE_CREDIT_CARD" &&
                process.env.GATSBY_SHOW_PAYMENT === "1") ||
              (state.user.paymentMethod && state.user.paymentMethod !== "BEFORE_CREDIT_CARD")
                ? state.user.paymentMethod
                : "AFTER_MERCHANT_ACCOUNT",
          },
        },
      }

    case SET_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload,
      }

    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        authProvider: action.payload.authProvider,
        user: {
          ...action.payload.user,
        },
      }

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }

    case SET_RECENT_ORDER:
      return {
        ...state,
        recentOrder: action.payload,
      }

    case UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: {
          ...action.payload,
        },
      }

    case SET_LOCATION_MANAGEMENT:
      return {
        ...state,
        locationManagement: action.payload,
      }

    case UPDATE_PROMO_DETAILS:
      return {
        ...state,
        order: {
          ...state.order,
          payment: {
            ...state.order.payment,
            promoDetails: {
              ...state.order.payment.promoDetails,
              ...action.payload,
            },
          },
        },
      }

    case SET_PROFILE_ADDRESS:
      return {
        ...state,
        profileAddress: action.payload,
      }

    default:
      throw new Error("Bad Action Type")
  }
}

export const AppContextProvider = ({ children, location }) => {
  const [state, dispatch] = useReducer(reducer, getSessionState())

  useEffect(() => {
    let mounted = true

    // Helps when we are using the Amplify login screen
    Hub.listen("auth", data => {
      switch (data.payload.event) {
        case "signIn":
          const attributes = data.payload.data.attributes
          if (mounted) {
            dispatch({
              type: SET_LOGGED_IN,
              payload: {
                loggedIn: true,
                user: {
                  sub: attributes.sub,
                  name: attributes.name,
                  email: attributes.email,
                  gatewayMID: attributes["custom:gatewayMid"],
                  closedLoopMID: attributes["custom:closedLoopMid"],
                  paymentMethod: attributes["custom:paymentMethod"],
                  cloverMerchantId: attributes["custom:cloverMerchantId"],
                  username: data.payload.data.username,
                },
                authProvider:
                  "custom:cloverMerchantId" in attributes
                    ? AUTH_PROVIDER_CLOVER
                    : "custom:source" in attributes &&
                      sourceList.includes(parseInt(attributes["custom:source"], 10))
                    ? parseInt(attributes["custom:source"], 10)
                    : AUTH_PROVIDER_PBC,
              },
            })
            fetchProfileAddress(attributes["custom:gatewayMid"]).then(profileAddress => {
              if (profileAddress && profileAddress.length > 0) {
                dispatch({
                  type: SET_PROFILE_ADDRESS,
                  payload: {
                    ...profileAddress[0],
                  },
                })
              }
            })
          }
          break
        case "signOut":
          if (mounted) {
            dispatch({
              type: SET_LOGGED_IN,
              payload: {
                loggedIn: false,
              },
            })
            dispatch({
              type: SET_PROFILE_ADDRESS,
              payload: {},
            })
          }
          break
        default:
          break
      }
    })

    // Since we're keeping track of a user in the global state (window.sessionStorage),
    // let's make sure we're actually logged in (Amplify/sessionStorage)
    const checkUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser()
        const attributes = currentUser.attributes
        dispatch({
          type: SET_LOGGED_IN,
          payload: {
            loggedIn: true,
            user: {
              sub: attributes.sub,
              name: attributes.name,
              email: attributes.email,
              gatewayMID: attributes["custom:gatewayMid"],
              closedLoopMID: attributes["custom:closedLoopMid"],
              paymentMethod: attributes["custom:paymentMethod"],
              username: currentUser.username,
              cloverMerchantId: attributes["custom:cloverMerchantId"],
            },
            authProvider:
              "custom:cloverMerchantId" in attributes
                ? AUTH_PROVIDER_CLOVER
                : "custom:source" in attributes &&
                  sourceList.includes(parseInt(attributes["custom:source"], 10))
                ? parseInt(attributes["custom:source"], 10)
                : AUTH_PROVIDER_PBC,
          },
        })
        fetchProfileAddress(attributes["custom:gatewayMid"]).then(profileAddress => {
          if (profileAddress && profileAddress.length > 0) {
            dispatch({
              type: SET_PROFILE_ADDRESS,
              payload: {
                ...profileAddress[0],
              },
            })
          }
        })
      } catch (e) {
        // not logged in?
        dispatch({
          type: SET_LOGGED_IN,
          payload: {
            loggedIn: false,
          },
        })
      }
    }

    checkUser().then()
    return () => {
      mounted = false
    }
  }, [dispatch])

  useEffect(() => {
    if (isBrowser()) {
      window.localStorage.setItem("pcoState", JSON.stringify(state))
    }
  }, [state])

  return (
    <AppStateContext.Provider value={state} location={location}>
      <AppDispatchContext.Provider value={dispatch} location={location}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

export default AppContextProvider
