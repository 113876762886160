import { Auth } from "aws-amplify"
import awsconfig from "../aws-exports"

export function initConfig() {
  const config = { ...awsconfig }
  if (typeof window !== "undefined") {
    config.storage = localStorage
  }
  config.graphql_headers = async () => ({
    // Authorization header should be the id_token and not an access_token
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  })
  return config
}
