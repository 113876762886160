import { Auth } from "@aws-amplify/auth"
import { initConfig } from "./amplify"

const customConfig = { ...initConfig(), authenticationFlowType: "CUSTOM_AUTH" }
Auth.configure(customConfig)

export const isBrowser = () => typeof window !== "undefined"

const getUsernameAndNonce = () => {
  if (!window.location.hash || window.location.hash.length < 2) {
    return null
  }
  const hash = window.location.hash.substr(1)
  const decodedHash = window.atob(hash)
  if (decodedHash.indexOf(":") === -1) {
    return null
  }
  return decodedHash.split(":")
}

export const handlePbcLogin = () => {
  return new Promise((resolve, reject) => {
    const usernameNonce = getUsernameAndNonce()
    if (Array.isArray(usernameNonce) && usernameNonce.length === 2) {
      const [username, nonce] = usernameNonce
      Auth.signIn(username)
        .then(user => {
          if (
            "CUSTOM_CHALLENGE" === user.challengeName &&
            "pbc_jwt" === user.challengeParam.cookieName
          ) {
            Auth.sendCustomChallengeAnswer(user, nonce)
              .then(_user => {
                resolve(_user)
              })
              .catch(error => {
                reject(error)
              })
          }
        })
        .catch(error => {
          reject(error)
        })
      return
    }

    // Fall through case
    const err = new Error("could not read required authentication details")
    reject(err)
  })
}
